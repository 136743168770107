import 'tailwindcss/tailwind.css'
import '../../styles/globals.css'

import type { AppProps } from 'next/app'

import Head from 'next/head'
import { QueryClient, QueryClientProvider } from 'react-query'
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core'
import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector'
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import { RPC_URLS } from '@hashnote/types/src/const'
import { HASHNOTE_ENV } from '../const'
import { ThemeProvider } from '../providers/ThemeProvider'

console.log('starting terminal', {
  sentryDsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  env: process.env.HASHNOTE_ENV ?? 'LOCAL',
  release: process.env.VERCEL_GIT_COMMIT_MESSAGE ?? 'LOCAL',
})

const queryClient = new QueryClient()

function TerminalApp({ Component, pageProps }: AppProps) {
  // issue with Nextjs types
  const ComponentAny = Component as any
  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
        <title>Terminal | Hashnote</title>
      </Head>
      <DynamicContextProvider
        settings={{
          environmentId: '39342c0a-c4cc-43f4-b5a2-1c65fc1a5095',
          walletConnectors: [EthereumWalletConnectors],
        }}
      >
        <DynamicWagmiConnector
          evmNetworks={[
            {
              blockExplorerUrls: ['https://etherscan.io/'],
              chainId: 1,
              chainName: 'Ethereum Mainnet',
              name: 'Ethereum Mainnet',
              iconUrls: ['https://app.dynamic.xyz/assets/networks/eth.svg'],
              nativeCurrency: { decimals: 18, name: 'Ether', symbol: 'ETH' },
              networkId: 1,
              privateCustomerRpcUrls: [
                RPC_URLS[HASHNOTE_ENV]['0x1'].terminalPrimary,
                RPC_URLS[HASHNOTE_ENV]['0x1'].terminalFallback,
              ],
              rpcUrls: ['https://cloudflare-eth.com'],
              vanityName: 'Ethereum',
            },
            {
              blockExplorerUrls: ['https://goerli.etherscan.io/'],
              chainId: 5,
              chainName: 'Ethereum Goerli',
              name: 'Ethereum Goerli',
              iconUrls: ['https://app.dynamic.xyz/assets/networks/eth.svg'],
              nativeCurrency: { decimals: 18, name: 'Ether', symbol: 'ETH' },
              networkId: 5,
              privateCustomerRpcUrls: [
                RPC_URLS[HASHNOTE_ENV]['0x5'].terminalPrimary,
                RPC_URLS[HASHNOTE_ENV]['0x5'].terminalFallback,
              ],
              rpcUrls: ['https://cloudflare-eth.com'],
              vanityName: 'Goerli',
            },
            {
              blockExplorerUrls: ['https://sepolia.etherscan.io/'],
              chainId: 11155111,
              chainName: 'Ethereum Sepolia',
              name: 'Ethereum Sepolia',
              iconUrls: ['https://app.dynamic.xyz/assets/networks/eth.svg'],
              nativeCurrency: {
                decimals: 18,
                name: 'Sepolia Ether',
                symbol: 'SepoliaETH',
              },
              networkId: 11155111,
              privateCustomerRpcUrls: [
                RPC_URLS[HASHNOTE_ENV]['0xAA36A7'].usycPortalPrimary as string,
                RPC_URLS[HASHNOTE_ENV]['0xAA36A7'].usycPortalFallback as string,
              ],
              rpcUrls: ['https://cloudflare-eth.com'],
              vanityName: 'Sepolia',
            },
          ]}
        >
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <ComponentAny {...pageProps} />
            </ThemeProvider>
          </QueryClientProvider>
        </DynamicWagmiConnector>
      </DynamicContextProvider>
    </>
  )
}

export default TerminalApp
