import { HashnoteEnv } from '@hashnote/types/src/const'
import { AnySupportedNetworkId } from '@hashnote/types/src/web3'

export const NETWORK_VAULTS_SUBGRAPH_URL_MAP: Record<AnySupportedNetworkId, string> = {
  '0x1': process.env.NEXT_PUBLIC_VAULTS_SUBGRAPH_ETH_MAINNET_URL ?? '',
  '0x5': process.env.NEXT_PUBLIC_VAULTS_SUBGRAPH_ETH_GOERLI_URL ?? '',
  '0xAA36A7': process.env.NEXT_PUBLIC_VAULTS_SUBGRAPH_ETH_SEPOLIA_URL ?? '',
}

export const NETWORK_AUCTIONS_SUBGRAPH_URL_MAP: Record<AnySupportedNetworkId, string> = {
  '0x1': process.env.NEXT_PUBLIC_AUCTIONS_SUBGRAPH_ETH_MAINNET_URL ?? '',
  '0x5': process.env.NEXT_PUBLIC_AUCTIONS_SUBGRAPH_ETH_GOERLI_URL ?? '',
  '0xAA36A7': 'DoesNotExist',
}

export const NETWORK_MARGIN_ENGINE_SUBGRAPH_URL_MAP: Record<AnySupportedNetworkId, string> = {
  '0x1': 'https://ethereum-mainnet.graph-eu.p2pify.com/e89bb79ff113f9304608538f52430d69/margin_engine_mainnet' ?? '',
  '0x5': 'https://ethereum-goerli.graph-eu.p2pify.com/f2897e590a10bcba3348c324819e0c4b/margin_engine_goerli' ?? '',
  '0xAA36A7': 'https://testnets.graph-eu.p2pify.com/56aef4db1aa5554142d3b6ec1f04a202/margin_engine_sepolia',
}
export const COIN_GECKO_API_URL = 'https://pro-api.coingecko.com/api/v3'
export const COIN_GECKO_API_KEY = process.env.NEXT_PUBLIC_COIN_GECKO_API_KEY

/**
 * Networks this deployment is configured for.
 */
export const ENVIRONMENT_SUPPORTED_NETWORKS = JSON.parse(
  process.env.NEXT_PUBLIC_ENVIRONMENT_SUPPORTED_NETWORKS ?? ('[]' as string)
) as AnySupportedNetworkId[]

/**
 * Name of the current deployment environment.
 */
export const HASHNOTE_ENV = (process.env.NEXT_PUBLIC_HASHNOTE_ENV ?? 'dev') as HashnoteEnv
